<adxad-loader
  *ngIf="isLoading || isLoadingStatus"
  [diameter]="40"
  adxadSidebarModalLoader
></adxad-loader>

<adxad-sidebar-modal-container *ngIf="dsp && !isLoading">
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="close()"></adxad-sidebar-modal-close-btn>
    <h1 adxadSidebarModalTitle>{{ dsp.name }}</h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section
      [hideSeparator]="true"
      adxadSidebarModalSection
    >
      <div
        (click)="copyValue(dsp.url)"
        adxadTooltip="Click to copy"
        class="url"
      >
        {{ dsp.url }}
        <span class="material-icons --icon"> filter_none </span>
      </div>
    </section>

    <section
      [hideSeparator]="true"
      adxadSidebarModalSection
      class="row"
    >
      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Api key"
      >
        <adxad-public-api-key
          [apiKey]="dsp.apiKey"
          [id]="dsp.id"
          type="dsp"
        />
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="External ID"
      >
        <div class="ext-id">
          {{ dsp.extId }}

          <span
            class="material-icons --icon"
            adxadTooltip="Click to copy"
            (click)="copyValue(dsp.extId)"
          >
            filter_none
          </span>
        </div>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Status"
      >
        <adxad-entity-status [entityStatus]="dsp.status"></adxad-entity-status>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Created at"
      >
        {{ dsp.createdAt | date: 'MM/dd/y H:mm:ss' }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Updated at"
      >
        {{ dsp.updatedAt | date: 'MM/dd/y H:mm:ss' }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Advert type"
      >
        {{ dsp.advType }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="dsp.rpsLimit"
        class="col-6"
        label="RPS limit"
      >
        {{ dsp.rpsLimit }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Response type"
      >
        {{ dsp.responseType }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Open RTB"
      >
        <span [ngClass]="dsp.isOpenRtb ? 'success' : 'error'"> {{ dsp.isOpenRtb ? 'Enabled' : 'Disabled' }} </span>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Cost model"
      >
        {{ dsp.costModel | uppercase }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Win notifications"
      >
        <span [ngClass]="dsp.isWinNotification ? 'success' : 'error'"> {{ dsp.isWinNotification ? 'Enabled' : 'Disabled' }} </span>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Test mode"
      >
        <span [ngClass]="dsp.testMode ? 'success' : 'error'"> {{ dsp.testMode ? 'Enabled' : 'Disabled' }} </span>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Is adverts demand"
      >
        <span [ngClass]="dsp.isInternal ? 'success' : 'error'"> {{ dsp.isInternal ? 'Yes' : 'No' }} </span>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="dsp.redirectTtl > 0"
        class="col-6"
        label="Redirect TTL"
      >
        {{ dsp.redirectTtl }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Default revshare (%)"
      >
        {{ dsp.commission }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="dsp.domains?.length"
        class="col-12"
        label="Redirect domains"
      >
        <adxad-chip-list>
          <adxad-chip *ngFor="let domain of dsp.domains"> {{ domain }} </adxad-chip>
        </adxad-chip-list>
      </adxad-sidebar-modal-info-block>
    </section>

    <adxad-tabs-nav *ngIf="dsp.filters?.length || dsp.geoRevshare?.length">
      <adxad-tab-link
        (click)="openTab(1)"
        *ngIf="dsp.filters?.length"
        [class.active]="showTab === 1"
      >
        Filters
      </adxad-tab-link>
      <adxad-tab-link
        (click)="openTab(2)"
        *ngIf="dsp.geoRevshare?.length"
        [class.active]="showTab === 2"
      >
        Geo revshare
      </adxad-tab-link>
    </adxad-tabs-nav>

    <!--  Filters  -->
    <ng-container *ngIf="showTab === 1">
      <div class="filters-list">
        <div
          *ngFor="let filter of dsp.filters; let i = index;"
          class="filters-list__item"
        >
          <div
            (click)="filter.isSelected = !filter.isSelected"
            [ngClass]="{'pointer': !!dsp.filters[i], 'opened': filter.isSelected}"
            *ngIf="dsp.filters[i].value.length > 0"
            class="filters-list__item-title"
          >
            {{ filter.label | transloco }}

            <ng-container *ngIf="dsp.filters[i]">
              <span class="filters-list__item-mode"> {{ (dsp.filters[i].mode ? 'include' : 'exclude') | lowercase }} </span>
              <div class="filters-list__item-count">{{ dsp.filters[i].value.length }}</div>
            </ng-container>
          </div>

          <div
            *ngIf="dsp.filters[i] && filter.isSelected"
            class="filters-list__item-value-list"
          >
            <div
              *ngFor="let value of dsp.filters[i].value"
              class="filters-list__item-value"
            >
              {{ value }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End Filters  -->
    <!--  Geo revshare  -->
    <ng-container *ngIf="showTab === 2">
      <div class="revshare-list">
        <div
          *ngFor="let revshare of dsp.geoRevshare; let i = index;"
          class="revshare-list__item"
        >
          <div class="item-geo">{{ revshare.geo }}</div>
          <div class="item-revenue">{{ revshare.revenue }}</div>
        </div>
      </div>
    </ng-container>
    <!-- End Geo revshare  -->
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <button
      (click)="openReportsGrid()"
      actionType="main-gray"
      adxadSidebarModalAction
      adxadTooltip="Statistics"
    >
      <span class="material-icons"> query_stats </span>
      Statistics
    </button>

    <button
      (click)="openEditForm()"
      actionType="main-blue"
      adxadSidebarModalAction
      type="button"
    >
      <span class="material-icons">edit</span>
      Edit
    </button>

    <button
      (click)="changeStatus(Status.active)"
      *ngIf="dsp.status === Status.stopped"
      actionType="main-green"
      adxadSidebarModalAction
    >
      <span class="material-icons">play_arrow</span>
      Activate
    </button>

    <button
      (click)="changeStatus(Status.stopped)"
      *ngIf="dsp.status === Status.active"
      actionType="main-red"
      adxadSidebarModalAction
    >
      <span class="material-icons">stop</span>
      Stop
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>
