import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { ExchangeDSPDetails, ExchangeDSPResponse } from '../../../../core/interface/exchange';
import { Status } from '../../../../core/configs';
import { AdxadSidebarModalRef } from '../../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { AdxadSidebarModalConfig } from '../../../../ui/modules/sidebar-modal/sidebar-modal-config';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { MessageService } from '../../../../core/services/message.service';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { ExchangeService } from '../../exchange.service';
import { DspFormComponent } from '../dsp-form/dsp-form.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { EXCHANGE_ROUTE } from '../../../../core/routes';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-dsp-view',
  templateUrl: './dsp-view.component.html',
  styleUrls: ['./dsp-view.component.scss']
})
export class ExchangeDspViewComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingStatus = false;
  dsp: ExchangeDSPDetails;
  showTab = 1;
  readonly Status = Status;

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadSidebarModalRef,
    private modal: AdxadModal,
    public config: AdxadSidebarModalConfig,
    private alerts: AdxadAlerts,
    private messageService: MessageService,
    private queryParamsService: QueryParamsService,
    private exchangeService: ExchangeService,
    private clipboard: Clipboard,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.config || !this.config.data || !this.config.data.id) {
      this.alerts.error('Oops something went wrong', 3000);
      this.close();
    }

    this.queryParamsService.add('sm_exchangeDspView', this.config.data.id);
    this.loadData();
  }

  /**
   * Load dsp data
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.exchangeService
      .getDsp({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: ExchangeDSPResponse) => {
          this.isLoading = false;
          this.dsp = result.data as ExchangeDSPDetails;
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  /**
   * Open edit ssp form in modal
   */
  openEditForm(): void {
    this.modal
      .open(DspFormComponent, {
        width: '1120px',
        data: this.dsp
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.loadData();
          }
        }
      });
  }

  /**
   * Activate or stop dsp
   *
   * @param {number} status
   */
  changeStatus(status: number): void {
    if (status === undefined || this.isLoadingStatus) {
      return;
    }

    this.isLoadingStatus = true;
    const data = {
      ids: [this.config.data.id],
      status: status
    };

    this.exchangeService
      .changeDspStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingStatus = false;

          if (result.status === 'OK') {
            this.messageService.add('reload-exchange-dsps-grid', { submit: true });

            this.dsp.status = result.data[0].status;
            const message = 'The dsp is ' + (this.dsp.status === this.Status.stopped ? 'stopped' : 'activated');
            this.alerts.success(message, 3000);
          }
        },
        error: () => (this.isLoadingStatus = false)
      });
  }

  /**
   * View tab
   *
   * @param tabIndex 1 = Creatives, 2 = Advanced
   */
  openTab(tabIndex: number): void {
    this.showTab = tabIndex;
  }

  /**
   * Close modal
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  /**
   * Open reports grid with filtering by ssp
   */
  openReportsGrid() {
    this.router
      .navigate(['exchange', EXCHANGE_ROUTE.simpleReport], {
        queryParams: {
          f_dsp: '[{"id":"' + this.dsp.extId + '","value":"' + this.dsp.name + '"}]',
          g_dsp: '1'
        }
      })
      .then(() => this.close());
  }

  /**
   * Copy value in buffer
   *
   * @param value - element value
   */
  copyValue(value): void {
    this.clipboard.copy(value);
    this.alerts.success('Copied success', 1500);
  }

  ngOnDestroy(): void {
    this.queryParamsService.remove('sm_exchangeDspView');
  }
}
