import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { PaginatorComponent } from '../../../../ui/modules/paginator/paginator.component';
import { DynamicGridComponent } from '../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { GroupsComponent } from '../../../reports/components/groups/groups.component';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import {
  DynamicGridAction,
  FilterChanges,
  FiltersConfig,
  GroupItem,
  GroupsConfig,
  ReportsData,
  ReportsRequest
} from '../../../../core/interface';
import { skip, switchMap, tap } from 'rxjs/operators';
import { LayoutService } from '../../../../layout/layout.service';
import { FiltersService } from '../../../../core/services/filters.service';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { ExchangeService } from '../../exchange.service';
import { GlobicaService } from '../../../../core/services/globica.service';
import { DspService } from '../../../../core/services/dsp.service';
import { TranslocoService } from '@jsverse/transloco';
import dayjs from 'dayjs';
import { SidebarFilterComponent } from '../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-quality-report-grid',
  templateUrl: './quality-report-grid.component.html',
  styleUrls: ['./quality-report-grid.component.scss']
})
export class QualityReportGridComponent implements OnInit {
  @ViewChild(PaginatorComponent, { static: true })
  paginator: PaginatorComponent;

  @ViewChild(DynamicGridComponent, { static: true })
  dynamicGrid: DynamicGridComponent;

  @ViewChild(GroupsComponent, { static: true })
  groups: GroupsComponent;

  isLoading = false;
  isNoResults = false;

  private destroyRef = inject(DestroyRef);

  private requestGrid$ = new BehaviorSubject<ReportsRequest>(null);
  private loadGrid$: Observable<ReportsData> = this.requestGrid$.pipe(
    skip(1),
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.exchangeService.getQualityReport(data))
  );

  constructor(
    public layoutService: LayoutService,
    public filter: FiltersService,
    private sidebarModal: AdxadSidebarModal,
    private alerts: AdxadAlerts,
    private exchangeService: ExchangeService,
    public globica: GlobicaService,
    private dspService: DspService,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.subscribeFilter();
    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: ReportsData) => {
        this.isLoading = false;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });
    this.paginator.init();
    this.loadData();
  }

  /**
   * @return {boolean} disable flag
   * If selected Break down by Date hour, calendar must be selected for last 103 days
   */
  get isValidDates(): boolean {
    if (!this.groups.result.includes('dateHour')) {
      return true;
    }

    if (!this.filter.request.size || !this.filter.request.get('from')) {
      return true;
    }

    const from = dayjs(this.filter.request.get('from') as string);
    const now = dayjs();

    return now.diff(from, 'days') < 103;
  }

  /**
   * Load groups & filter configs
   * Then load grid data
   */
  loadData(): void {
    zip(this.exchangeService.getQualityReportGroups(), this.dspService.getFilters('exchangeQuality'))
      .pipe(
        tap(() => (this.isLoading = true)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: ([groups, filters]: [GroupsConfig, FiltersConfig]) => {
          this.isLoading = false;

          this.groups.setConfig(groups);
          this.filter.init({ data: filters.data });
          this.loadGrid();
        },
        error: () => (this.isLoading = false)
      });
  }

  /**
   * Load quality report grid
   */
  loadGrid(): void {
    if (!this.isValidDates) {
      this.alerts.error(this.translate.translate('dateHourAlert'), 5000);
      return;
    }

    let sort = this.dynamicGrid?.sort?.active;
    let direction = this.dynamicGrid?.sort?.direction;
    const groups = this.groups.result;
    const filter = this.filter.request;

    if (!sort || !this.dynamicGrid?.isUserSetSort) {
      const group = ['dateHour', 'date', 'week', 'month'].find(x => groups.indexOf(x) !== -1);
      if (group) {
        sort = group;
        direction = 'asc';
        this.dynamicGrid.setSort(sort, direction);
      }
    }

    const request = {
      limit: this.paginator.limit,
      page: this.paginator.page,
      filter,
      groups,
      sort,
      direction
    } as ReportsRequest;
    this.requestGrid$.next(request);
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.filter.markAsApplied();
    this.isNoResults = false;
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.globica.trackEventGoals('buttonFiltersApply_click', {
      filters: this.filter.globicaFilterRequest,
      break_by: [this.groups.result]
    });

    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  /**
   * Change order of columns
   *
   * @param {GroupItem[]} e
   */
  changeColumnsOrder(e: GroupItem[]): void {
    const filterableCols = this.dynamicGrid.columns.slice(0, e.length);
    e.forEach((group, i) => {
      this.dynamicGrid.columns[i] = filterableCols.find(x => x.id === group.id);
    });
    this.filter.markAsNotApplied();
  }

  /**
   * Toggle element in reports grid
   *
   * @param {DynamicGridAction} e
   */
  toggleFilter({ value }: DynamicGridAction): void {
    this.filter.toggleFilterableValue(value.colId, value.value);
  }

  /**
   * Set main report grid
   * @param {ReportsData} result
   */
  private setGrid(result: ReportsData): void {
    if (result.data.length && result.meta.total > 0) {
      this.globica.trackEventGoals('resultExchangeQualityStat_show', {
        rows_number: result.meta.total
      });
    }

    if (!result || !result.meta || !result.meta.columns) {
      this.alerts.error(result.message || this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    this.isNoResults = !result.data.length;

    if (!this.isNoResults) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    }
  }
}
